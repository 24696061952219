.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


/* SET SIZE */

.screen{
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.size{
  width: 505px;
  height: 500px;
  background-color: black;
  justify-content: center;
}

.edit-screen{
  display: flex;
  min-height: 80vh;
  max-height: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
  background-color: black;
}

.edit-size{
  width: 505px;
  height: 500px;
  background-color: black;
}
