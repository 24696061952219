@import "/node_modules/include-media/dist/include-media";

.main-nft {
  height: 500px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  width: 505px;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  @include media("<=889px") {
    width: 100%;
    border-radius: 0px;
    position: relative;
    background-position: center;
  }
}

.menu {
  width: 25%;
  min-height: 500px;
  max-height: 500px;
  @include media("<=889px") {
    width: 65%;
    min-height: 350px;
    max-height: 350px;
  }
}

.menu-items {
  @include media("<=889px") {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
}

/*
 *  STYLE 3
 */
/* 
.menu::-webkit-scrollbar-track
 {
     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
     background-color: #F5F5F5;
 }
 
.menu::-webkit-scrollbar
 {
     width: 6px;
     background-color: #F5F5F5;
 }
 
.menu::-webkit-scrollbar-thumb
 {
     background-color: #000000;
 }
  */

.buttons > button {
  border: #a43322 solid 0.5px;
  padding: 5px;
  margin: 5px;
}

.buttons > button:hover {
  color: white;
  background-color: black;
}

.menu-icon {
  color: white;
  cursor: pointer;
}
.menu-icon:hover {
  color: black;
}

.inside-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #b6201f;
  border-radius: 50px;
  font-weight: 700;
  font-family: "Lexend Deca", Arial, Helvetica, sans-serif;
  @include media("<=889px") {
    font-size: 14px;
    width: 100%;
  }
  .photos-button {
    background-color: #b6201f;
    // padding: 7.5px 22px;
    border-radius: 15px;
    transition: 0.2s ease-in-out;
  }
  .details-button {
    background-color: #b6201f;
    // padding: 7.5px 22px;
    border-radius: 15px;
    transition: 0.2s ease-in-out;
  }
}
.inside-btn > button:hover {
  color: #36ab63;
}
.nft-title {
  color: #dd0016;
  font-weight: 800;
  font-size: 30px;
  letter-spacing: 1.2px;
  font-family: "Mouse Memoirs", Arial, Helvetica, sans-serif;
  @include media("<=889px") {
    font-size: 24px;
  }
}
