
.loader {
  position: fixed;
  z-index: 3;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  list-style: none;
}

@keyframes loadbars {
      0%{
          height: 10px;
          margin-top: 25px;
      }
      50%{
          height:50px;
          margin-top: 0px;
      }
      100%{
          height: 10px;
          margin-top: 25px;
      }
  }

li {
  background-color: #ffffff;
  width: 10px;
  height: 10px;
  float: right;
  margin-right: 5px;
  box-shadow: 0px 100px 20px rgba(0, 0, 0, 0.2);
}
li:first-child {
  -webkit-animation: loadbars 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) infinite
    0s;
}
li:nth-child(2) {
  -webkit-animation: loadbars 0.6s ease-in-out infinite -0.2s;
}
li:nth-child(3) {
  -webkit-animation: loadbars 0.6s ease-in-out infinite -0.4s;
}
