.skin-section {
  font-size: 12px;
  font-family: "Lexend Deca", Arial, Helvetica, sans-serif;
  line-height: 1.1;
}

.skin-img {
  width: 55%;
  height: 55%;
  border-radius: 15px;
}

.photo-title {
  margin-bottom: 5px;
}
